<template>
  <div>
    <div class="pd-5">
      <div class=" text-center pt-5">
        <i class="fas fa-list mr-4 icone-principal"></i>
        <h3>Minhas apólices</h3>
        <div class="h-line" style="width: 142px"></div>
        <p class="mt-3 editar-conta">
          Aqui você pode ver todas as suas apolices.
        </p>
      </div>
    </div>
    <hr class="mt-1" />

    <div class="row mb-3" v-if="loading == false && seguros.length == 0">
      <div class="col-12 text-center " style="align-self: center;">
        <img
          class=" rounded-circle mt-2"
          src="/img/bot-miguel-verde.png"
          width="64"
          alt="Assistente virtual Miguel"
        />
        <h5 class="font-weight-light mt-3">
          Você ainda não tem nenhum seguro registrado com seu CPF.
        </h5>
      </div>
      <div class="text-center col-12">
        <b-button class=" salvar-dados mt-3 " variant="success" to="/cotacao">
          <span>Simular e contratar agora</span>
        </b-button>
      </div>
    </div>
    <div class="minhas-apolices">
      <div class="row">
        <div class="col-md-6">
          <!-- <h4>Assinaturas de seguro</h4> -->
        </div>
        <div
          class="col-md-6 text-md-right mb-3 "
          v-if="loading == false && seguros.length > 0"
        >
          <button class="btn btn-link pl-0 py-0" v-b-modal.modal-alterar-cartao>
            <i class="far fa-credit-card"></i>
            Alterar cartão de cobrança
          </button>
        </div>
      </div>

      <!-- <hr class="mt-1" /> -->

      <div v-if="loading" class="text-center">
        <lottie-player
          src="/anim/mountain_bike.json"
          background="transparent"
          speed="1.7"
          style="width: 72px; height: 72px; margin:0 auto;"
          autoplay
          loop
          class="mt-3"
        ></lottie-player>
        <div>Carregando, por favor aguarde...</div>
      </div>

      <div v-if="loading == false && seguros.length > 0">
        <div
          class="card mb-3"
          v-for="(item, index) in seguros"
          :key="'subscription' + index"
          :class="
            !item.planoOpcional &&
            item.idTipoAssinatura === config.ID_TIPO_ASSINATURA_MENSAL &&
            item.idSituacao === config.SITUACAO_APROVADA
              ? 'card-plano-pessoal'
              : ''
          "
        >
          <div class="card-header">
            <div class="row">
              <div class="col">
                <strong
                  >Seguro Bike {{ item.plano }} #{{ item.codReferencia }}
                </strong>
                <span
                  class=" ml-1 badge badge-pill badge-warning"
                  v-if="
                    item.planoOpcional &&
                      !item.ehEmAnalise &&
                      item.idSituacao === config.SITUACAO_APROVADA
                  "
                  >+ Acidente pessoal {{ item.planoOpcional }}</span
                >
                <div>
                  <small>
                    <span>Criado em:</span>
                    <span class="ml-1">{{
                      formatacaoData.formatarDataLocal(item.dataCriado)
                    }}</span>
                  </small>
                </div>
              </div>
              <div class="text-right pr-2 pt-2">
                <span class="h5 font-weight-normal">{{
                  formatacaoMoeda3(item.valorPlano)
                }}</span>
                <small
                  v-if="
                    item.idTipoAssinatura == config.ID_TIPO_ASSINATURA_ANUAL
                  "
                  class="text-muted"
                  >/ ano</small
                >
                <small
                  v-else-if="
                    item.idTipoAssinatura == config.ID_TIPO_ASSINATURA_SEMESTRAL
                  "
                  class="text-muted"
                  >/ sem.</small
                >
                <small v-else class="text-muted">/ mês</small>
              </div>
            </div>
          </div>
          <div class="card-body pb-0">
            <div class="row">
              <div class="col-sm-6 col-lg-4 mb-3">
                <label>Bike:</label>
                <div>
                  {{ item.modeloBike }} {{ item.marcaBike }} {{ item.anoBike }}
                </div>
                <b-alert
                  v-if="
                    item.idTipoAssinatura ===
                      config.ID_TIPO_ASSINATURA_MENSAL &&
                      item.idSituacao === config.SITUACAO_APROVADA &&
                      (!item.temAcessorio || !item.planoOpcional)
                  "
                  class="mt-3 font-size-infor"
                  show
                  variant="info"
                  ><strong> Fazer upgrade no meu seguro</strong>
                  <a
                    href="javascript:void(0)"
                    target="_blank"
                    class="d-block mb-1"
                    v-if="!item.planoOpcional"
                    @click.prevent="rota(item)"
                  >
                    <i class="fas fa-plus"></i> Cobertura acidente pessoal
                  </a>
                  <a
                    v-if="!item.temAcessorio"
                    href="javascript:void(0)"
                    target="_blank"
                    class="d-block mb-3"
                    @click.prevent="rota2(item)"
                  >
                    <i class="fas fa-plus"></i> Cobertura para acessório
                  </a>
                </b-alert>
                <div v-if="item.temAcessorio">
                  <div class="mt-3" v-if="!item.urlNotaFiscalAcessorio">
                    <a
                      href="javascript:void(0)"
                      @click.prevent="exibirModalAcessorio(item.idSeguro)"
                    >
                      <i class="fas fa-file-alt"></i> Enviar nota fiscal do
                      acessório</a
                    >
                  </div>
                  <div class="mt-3" v-else>
                    <a :href="item.urlNotaFiscalAcessorio" target="_blank">
                      <i class="fas fa-file-alt"></i> Abrir nota fiscal do
                      acessório</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-sm-6 col-lg-3 mb-3">
                <label>Situação:</label>
                <div>
                  <span
                    class="badge badge-pill badge-success"
                    v-if="item.idSituacao == config.SITUACAO_APROVADA"
                    >Seguro ativado</span
                  >
                  <span
                    class="badge badge-pill badge-info"
                    v-if="item.idSituacao == config.SITUACAO_ENVIADA"
                    >Em aprovação</span
                  >
                  <span
                    class="badge badge-pill badge-danger"
                    v-if="item.idSituacao == config.SITUACAO_REPROVADA"
                    >Seguro rejeitado</span
                  >
                  <span
                    class="badge badge-pill badge-secondary"
                    v-if="item.idSituacao == config.SITUACAO_CANCELADA"
                    >Seguro cancelado</span
                  >
                </div>
              </div>

              <div class="col-sm-12 col-lg-5 mb-3">
                <div
                  v-if="
                    item.inspecStatus == config.STATUS_INSPEC_AGUARDANDO &&
                      item.idSituacao == config.SITUACAO_ENVIADA
                  "
                >
                  <label>Vistoria:</label>
                  <a :href="item.urlInspec" target="_blank" class="d-block">
                    <i class="fas fa-external-link-square-alt"></i> Link de
                    vistoria inspec
                  </a>
                </div>
                <div
                  v-if="
                    item.idSituacao == config.SITUACAO_APROVADA ||
                      item.idSituacao == config.SITUACAO_CANCELADA
                  "
                >
                  <label>Informações:</label>

                  <a
                    v-if="item.urlApolice && item.seguroEssor"
                    :href="item.urlApolice"
                    target="_blank"
                    class="d-block mb-3"
                  >
                    <i class="fas fa-file-alt"></i> Apólice do seguro
                  </a>

                  <a
                    v-if="item.seguroEssor"
                    :href="config.URL_MANUAL_SEGURO"
                    target="_blank"
                    class="d-block mb-3"
                  >
                    <i class="fas fa-book"></i> Manual do seguro
                  </a>
                  <a
                    v-else
                    :href="item.urlManual"
                    target="_blank"
                    class="d-block mb-3"
                  >
                    <i class="fas fa-book"></i> Manual do seguro
                  </a>

                  <a
                    v-if="item.seguroEssor"
                    :href="montarUrlCertificado(item.idSeguro)"
                    target="_blank"
                    class="d-block mb-3"
                  >
                    <i class="fas fa-book"></i> Certificado do seguro
                  </a>
                  <a
                    v-else
                    :href="item.urlApolice"
                    target="_blank"
                    class="d-block mb-3"
                  >
                    <i class="fas fa-book"></i> Certificado do seguro
                  </a>
                  <!-- <a
                    v-if="item.idSituacao == config.SITUACAO_APROVADA"
                    href="javascript:void(0)"
                    class="d-block mb-3"
                    @click.prevent="exibirModalAberturaSinistro(item)"
                  >
                    <i class="fas fa-book"></i> Abrir sinistro
                  </a> -->
                  <a
                    v-if="item.idSituacao == config.SITUACAO_APROVADA"
                    target="_blank"
                    :href="
                      `https://api.whatsapp.com/send?phone=${
                        config.WHATSAPP_NUMERO
                      }&text=Olá, meu nome é ${auth.getUserName()}, acessei o painel do seguro e desejo comunicar um sinistro com a bike ${
                        item.marcaBike
                      } - ${item.modeloBike}.`
                    "
                  >
                    Abrir sinistro
                  </a>
                </div>
                <!-- <div v-if="item.idSituacao == config.SITUACAO_ENVIADA">
                <small>
                  Seu pedido de seguro já foi recebido por nossa parceira INSPEC. Caso você ainda não tenha realizado a vistoria
                  <a
                    :href="item.urlInspec"
                    target="_blank"
                  >clique aqui</a>.
                </small>
              </div> -->
                <div v-if="item.idSituacao == config.SITUACAO_REPROVADA">
                  <small>
                    O seguro da sua bike não foi aprovado. Entre em contato pelo
                    e-mail
                    <a
                      href="mailto:seguro@bikeregistrada.com.br?Subject=Gostaria de informações sobre meu seguro"
                      target="_blank"
                      >seguro@bikeregistrada.com.br</a
                    >
                    ou
                    <a
                      target="_blank"
                      :href="
                        `https://api.whatsapp.com/send?phone=${
                          config.WHATSAPP_NUMERO
                        }&text=Olá, meu nome é ${auth.getUserName()} e preciso de atendimento.`
                      "
                    >
                      Whatsapp.
                    </a>
                  </small>
                </div>
              </div>
            </div>
            <div class="mt-1">
              <b-alert
                show
                variant="info"
                v-if="
                  item.planoOpcional &&
                    item.ehEmAnalise &&
                    item.idSituacao === config.SITUACAO_APROVADA
                "
              >
                Parabéns! Você fez o upgrade do seu seguro e adicionou a
                cobertura de acidente pessoal {{ item.planoOpcional }}. A
                cobertura de acidente pessoal estará em vigor na sua próxima
                apólice que será renovada na data
                {{ formatacaoData.formatarDataLocal(item.dataRenovacao) }}.
              </b-alert>
            </div>
             <div class="mt-1">
              <b-alert
                show
                variant="info"
                v-if="
                  item.nomeAcessorio &&
                    item.ehEmAnaliseCobertura &&
                    item.idSituacao === config.SITUACAO_APROVADA
                "
              >
                Parabéns! Você fez o upgrade do seu seguro e adicionou a
                cobertura do acessório {{ item.nomeAcessorio }}. A
                cobertura para o seu acessório estará em vigor na sua próxima
                apólice que será renovada na data
                {{ formatacaoData.formatarDataLocal(item.dataRenovacao) }}.
              </b-alert>
            </div>
          </div>
          <div class="card-footer p-1 text-right">
            <button
              v-if="exibirCancelarSeguro(item)"
              class="btn btn-link btn-sm text-danger"
              @click="onClickCancelarSeguro(item)"
            >
              Cancelar este seguro
            </button>
          </div>
        </div>
      </div>
    </div>
    <ModalCancelarSeguroCliente :seguro="seguro" />

    <ModalAberturaSinistro
      :showModal="showModalSinistro"
      :seguro="seguro"
      @hide="hideModalModalAberturaSinistro"
    />
    <ModalComprovanteAcessorio
      :showModalAcessorio="showModalAcessorio"
      :idSeguro="idSeguroAcessorio"
      @hideAcessorio="hideModalModalAcessorio"
    />
    <ModalAlterarCartaoCobranca :idCliente="idCliente" />
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

import config from "../../config";
import auth from "../../auth";
import formatacaoMoeda3 from "../../helpers/formatacaoMoeda3";
import formatacaoData from "../../helpers/formatacaoData";
import formatacaoTelefone from "../../helpers/formatacaoTelefone";
import captureError from "../../helpers/captureError";

import ModalCancelarSeguroCliente from "../../components/Usuario/ModalCancelarSeguroCliente";
import ModalAlterarCartaoCobranca from "../../components/Usuario/ModalAlterarCartaoCobranca";
import ModalAberturaSinistro from "../../components/Usuario/ModalAberturaSinistro";
import ModalComprovanteAcessorio from "../../components/Usuario/ModalComprovanteAcessorio";
export default {
  components: {
    ModalCancelarSeguroCliente,
    ModalAlterarCartaoCobranca,
    ModalAberturaSinistro,
    ModalComprovanteAcessorio,
  },
  data() {
    return {
      auth,
      config,
      formatacaoMoeda3,
      formatacaoData,
      formatacaoTelefone,
      seguros: [],
      idCliente: undefined,
      loading: true,
      seguro: undefined,
      showModalSinistro: false,
      showModalAcessorio: false,
      idSeguroAcessorio: undefined,
      cliente:{}
    };
  },
  created() {
    this.populateUserInfo();
    this.popularAssinaturas(this.idCliente);
  },

  methods: {
    rota(seguro) {
      const userInfo = auth.getUserInfo();
      this.$router.push({
        name: "adicionar-acidente-pessoal",
        params: {
          id: seguro.idSeguro,
          valorBike: seguro.valorBike,
          data: seguro.dataRenovacao,
          nascimento: userInfo.dataNascimento,
          uf: userInfo.uf
        },
      });
    },
    rota2(seguro) {
      this.$router.push({
        name: "adicionar-acessorio",
        params: {
          id: seguro.idSeguro,
          valorBike: seguro.valorBike,
          data: seguro.dataRenovacao,
        },
      });
    },
    populateUserInfo() {
      var userInfo = auth.getUserInfo();
      if (!userInfo) {
        this.$router.push("/login");
      }
      if (userInfo) {
        this.idCliente = userInfo.id;
      }
    },
    async popularAssinaturas(idCliente) {
      try {
        this.loading = true;
        var response = await axios.get(
          `${config.API_URLV2}/seguro/porCliente?idCliente=${idCliente}`
        );

        this.seguros = response.data || [];
        this.loading = false;
      } catch (error) {
        captureError(error);

        this.loading = false;

        Swal.fire({
          title: "Não foi possível buscar suas assinaturas de seguro",
          text: "Tente novamente mais tarde ou contate o suporte.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      }
    },
    exibirModalAberturaSinistro(seguro) {
      this.seguro = seguro;
      this.showModalSinistro = true;
    },
    hideModalModalAberturaSinistro(status) {
      this.showModalSinistro = false;
      if (status.modificou) {
        this.populateUserInfo();
      }
    },
    exibirModalAcessorio(idSeguro) {
      this.idSeguroAcessorio = idSeguro;
      this.showModalAcessorio = true;
    },
    hideModalModalAcessorio(status) {
      this.showModalAcessorio = false;
      if (status.modificou) {
        this.popularAssinaturas(this.idCliente);
      }
    },

    exibirCancelarSeguro(seguro) {
      return seguro.idSituacao == config.SITUACAO_APROVADA;
    },
    async onClickCancelarSeguro(seguro) {
      this.seguro = seguro;
      this.$bvModal.show("modal-cancelar-seguro-cliente");
    },
    onSeguroCancelado() {
      this.popularAssinaturas(this.idCliente);
    },
    montarUrlCertificado(idSeguro) {
      return `${config.API_URLV2}/seguro/GerarCertificado/${idSeguro}`;
    },
  },
};
</script>

<style scoped>
.row {
  margin: 0px;
}
.box-line {
  display: flex;
  flex-direction: column;
  align-items: self-end;
  justify-content: center;
}
label {
  margin-bottom: 0;
}
.icone-principal {
  font-size: 43px;
  color: #28a745;
  background: -webkit-linear-gradient(#76b566, #28a745);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.minhas-apolices {
  padding-left: 100px;
  padding-right: 100px;
}
.card-plano-pessoal {
  border-start-end-radius: 30px;
  border-start-start-radius: 30px;
  border-block-start: 0px;
}
.btn-block-desktop {
  display: block;
  width: 100%;
  margin-bottom: -1px;
  font-size: 20px;
  border-start-end-radius: 20px;
  border-start-start-radius: 20px;
}
.salvar-dados {
  padding: 12px;
  border-radius: 10px;
  width: 50%;
}
@media (max-width: 578px) {
  .minhas-apolices {
    padding-left: 50px;
    padding-right: 50px;
  }
}
@media (max-width: 460px) {
  .minhas-apolices {
    padding-right: 20px;
    padding-left: 20px;
  }
  .salvar-dados {
    width: 100%;
    border-radius: 10px;
  }
}
</style>
