<template>
  <div>
    <ValidationObserver ref="form">
      <form ref="formElm">
        <div class="card-wrapper"></div>

        <div class="row mt-4">
          <div class="col-md-8 mb-3">
            <label for="firstName">Número do cartão</label>
            <ValidationProvider
              name="número do cartão"
              rules="required|min:13"
              v-slot="{ errors, classes }"
            >
              <input
                type="tel"
                class="form-control"
                :class="classes"
                name="cardNumber"
                v-model="card.number"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-6 col-md-4 mb-3">
            <label for="lastName">Validade</label>
            <ValidationProvider
              name="validade"
              rules="required|min:5|expiracao_cartao"
              v-slot="{ errors, classes }"
            >
              <input
                type="tel"
                class="form-control"
                :class="classes"
                name="cardExpiry"
                placeholder="mm/aa"
                v-mask="'##/##'"
                v-model="card.expiry"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-6 col-md-4 order-md-2 mb-3">
            <label for="lastName">CVC</label>
            <ValidationProvider
              name="cvc"
              rules="required|min:2"
              v-slot="{ errors, classes }"
            >
              <input
                type="tel"
                class="form-control"
                :class="classes"
                name="cardCvc"
                v-model="card.cvc"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-md-8 mb-3">
            <label for="firstName">Nome impresso no cartão</label>
            <ValidationProvider
              name="nome"
              rules="required|min:5|max:26"
              v-slot="{ errors, classes }"
            >
              <input
                type="text"
                class="form-control"
                :class="classes"
                name="cardName"
                v-model="card.name"
                maxlength="100"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
        </div>

        <div class="text-right">
          <img
            class="mt-2"
            src="/img/ssl-site-seguro.webp"
            width="120"
            alt="Site seguro"
          />
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import Card from "card";
import Swal from "sweetalert2";
import config from "../config";
import helper from "../helpers/helper";

export default {
  data() {
    return {
      card: {
        number: undefined,
        name: undefined,
        expiry: undefined,
        cvc: undefined,
      },
    };
  },
  mounted() {
    initCardPlugin(this.$refs.formElm);

    //Configurando pagamento via Iugu
    if (window["Iugu"]) {
      window["Iugu"].setAccountID(config.IUGU_ACCOUNT);
      window["Iugu"].setTestMode(config.IUGU_TEST_MODE);
    }

    this.verificarScriptIugu();
  },
  methods: {
    async getHash() {
      var isValid = await this.$refs.form.validate();
      if (!isValid) {
        return undefined;
      }

      var hashCartao = await this.gerarHashCartao();
      return hashCartao;
    },

    async gerarHashCartao() {
      try {
        var partesValidade = (this.card.expiry || "").split("/");
        var mes = partesValidade[0];
        var ano = partesValidade[1];

        var partesNome = helper.quebrarNome(this.card.name);

        var hash = await this.gerarHashIuguAsync({
          number: this.card.number,
          expirationMonth: mes,
          expirationYear: ano,
          firstName: partesNome[0],
          lastName: partesNome[1],
          cvc: this.card.cvc,
        });
        return hash;
      } catch (error) {
        await Swal.fire({
          icon: "error",
          title: "Atenção!",
          text: "Cartão inválido. Por favor verifique os dados.",
        });
        return undefined;
      }
    },

    gerarHashIuguAsync(dados) {
      return new Promise((resolve, reject) => {
        var cc = window["Iugu"].CreditCard(
          dados.number,
          dados.expirationMonth,
          dados.expirationYear,
          dados.firstName,
          dados.lastName,
          dados.cvc
        );

        var valido = cc.valid();

        if (valido == false) {
          reject("Cartão inválido. Por favor verifique os dados.");
        }

        try {
          window["Iugu"].createPaymentToken(cc, function (data) {
            if (data.errors) {
              reject("Erro salvando cartão: " + JSON.stringify(data.errors));
            } else {
              resolve(data.id);
            }
          });
        } catch (e) {
          reject("Erro ao criar token Iugu: " + JSON.stringify(e));
        }
      });
    },

    verificarScriptIugu() {
      setTimeout(() => {
        // var naoCarregouScriptsIugu =
        //   window["Iugu"] == undefined ||
        //   window["ka"] == undefined ||
        //   window["ka"]["sessionId"] == undefined;

       var naoCarregouScriptsIugu =
          window["Iugu"] == undefined;

        if (naoCarregouScriptsIugu) {
          Swal.fire({
            icon: "error",
            title: "Atenção!",
            text:
              "Parece que seu navegador possui alguma extensão que está bloqueando o pagamento. Tente desabilitar as extensões ou tente acessar de outro navegador.",
          }).then(() => {
            window.location.reload();
          });
        }
      }, 1618 * 2);
    },
  },
};

function initCardPlugin(formElm) {
  new Card({
    // a selector or DOM element for the form where users will
    // be entering their information
    form: formElm, // *required*
    // a selector or DOM element for the container
    // where you want the card to appear
    container: ".card-wrapper", // *required*

    formSelectors: {
      numberInput: 'input[name="cardNumber"]', // optional — default input[name="number"]
      expiryInput: 'input[name="cardExpiry"]', // optional — default input[name="expiry"]
      cvcInput: 'input[name="cardCvc"]', // optional — default input[name="cvc"]
      nameInput: 'input[name="cardName"]', // optional - defaults input[name="name"]
    },

    //width: 200, // optional — default 350px
    formatting: true, // optional - default true

    // Strings for translation - optional
    messages: {
      validDate: "valid\ndate", // optional - default 'valid\nthru'
      monthYear: "mm/yy", // optional - default 'month/year'
    },

    // Default placeholders for rendered fields - optional
    placeholders: {
      name: "SEU NOME",
    },

    // if true, will log helpful messages for setting up Card
    debug: false, // optional - default false
  });
}
</script>

<style>
.jp-card {
  min-width: auto !important;
}
</style>