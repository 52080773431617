<template>
  <b-modal
    id="modal-alterar-cartao"
    title="Alterar cartão de cobrança"
    size="md"
    variant="dark"
    @show="abriuModal"
    :hide-footer="loadingGeral"
  >
    <div v-if="loadingGeral" class="text-center">
      <lottie-player
        src="/anim/mountain_bike.json"
        background="transparent"
        speed="1.7"
        style="width: 72px; height: 72px; margin: 0 auto"
        autoplay
        loop
        class="mt-3"
      ></lottie-player>
      <div>Carregando informações do cartão...</div>
    </div>

    <div v-else>
      <div>
        <strong>Cartão de cobrança atual:</strong>
        <div class="row my-3">
          <div class="col-md-auto">
            <i class="far fa-credit-card fa-3x text-muted"></i>
          </div>
          <div class="col-md-8">
            <div>{{ cartaoAtual.numero }}</div>
            <div>{{ cartaoAtual.nome }}</div>
          </div>
        </div>
      </div>

      <div class="">
        <strong>Dados do novo cartão de crédito:</strong>
        <CreditCardForm ref="creditCard" class="mt-3" />
      </div>
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <div class="row mx-0 w-100 small-gutters">
        <div class="col-6">
          <button
            class="btn btn-success btn-block"
            :disabled="loadingSalvar"
            @click="onClickSalvar"
          >
            <span v-if="loadingSalvar">Salvando...</span>
            <span v-else>Salvar cartão</span>
          </button>
        </div>
        <div class="col-6">
          <button class="btn btn-secondary btn-block" @click="cancel">
            Cancelar
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import CreditCardForm from "../CreditCardForm";

export default {
  props: {
    idCliente: {
      type: String,
      required: true,
    },
  },
  components: {
    CreditCardForm,
  },
  data() {
    return {
      config,
      loadingGeral: true,
      loadingSalvar: false,
      cartaoAtual: {
        numero: undefined,
        nome: undefined,
      },
    };
  },
  methods: {
    abriuModal() {
      this.loadingGeral = true;
      this.popularCartaoAtual();
    },
    async popularCartaoAtual() {
      try {
        this.loadingGeral = true;
        var response = await axios.get(
          `${config.API_URLV2}/seguro/CartaoCobranca?idCliente=${this.idCliente}`
        );

        this.cartaoAtual.nome = response.data.nomeNoCartao;
        this.cartaoAtual.numero = response.data.numeroCartao;

        this.loadingGeral = false;
      } catch (e) {
        captureError(e);

        await Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível carregar as informações do seu cartão. Tente novamente mais tarde ou contate o suporte.",
        });
        this.$bvModal.hide("modal-alterar-cartao");
      }
    },
    async onClickSalvar() {
      this.loadingSalvar = true;
      var hashCartao = await this.$refs.creditCard.getHash();
      if (!hashCartao) {
        this.loadingSalvar = false;
        return;
      }

      var result = await this.salvarCartao(hashCartao);
      this.loadingSalvar = false;

      if (result) {
        this.$bvModal.hide("modal-alterar-cartao");
      }
    },
    async salvarCartao(hashCartao) {
      try {
        var data = {
          idCliente: this.idCliente,
          tokenCartao: hashCartao,
        };
        await axios.post(
          `${config.API_URLV2}/seguro/AlterarCartaoCobranca`,
          data
        );

        Swal.fire({
          title: "Cartão alterado com sucesso.",
          icon: "success",
        });

        return true;
      } catch (e) {
        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível alterar o cartão. Tente novamente mais tarde ou contate o suporte.",
        });

        return false;
      }
    },
  },
};
</script>
