<template>
  <b-modal
    id="modal-cancelar-seguro-cliente"
    title="Deseja cancelar o seguro?"
    size="md"
    variant="dark"
  >
    <p>
      Poxa ficamos tristes por você cancelar o seu seguro, mas entendemos 😞
    </p>

    <div class="alert alert-warning">
      Atenção: Ao cancelar o seguro da sua bike, você estará completamente
      desprotegido(a), e o que mais queremos é que seu pedal seja 100% seguro.
    </div>

    <template v-slot:modal-footer="{ cancel }">
      <div class="row mx-0 w-100">
        <div class="col-6">
          <a
            class="btn btn-danger btn-block"
            target="_blank"
            :href="
              `https://api.whatsapp.com/send?phone=${config.WHATSAPP_NUMERO}&text=Olá meu nome é ${seguro.nomeCliente} e quero cancelar o seguro da minha bike ${seguro.marcaBike}`
            "
            >Cancelar mesmo assim</a
          >
        </div>
        <div class="col-6">
          <button class="btn btn-success btn-block" @click="cancel">
            Continuar seguro
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import config from "../../config";

export default {
  props: ["seguro"],
  data() {
    return {
      config,
    };
  },
  components: {},
  methods: {},
};
</script>

<style scoped>
.alert-warning {
  font-size: 1rem;
}
</style>
