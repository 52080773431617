<template>
  <b-modal
    :visible="showModal"
    title="Abertura de sinistro"
    id="modal-center"
    @hide="hideModal"
    :hide-footer="true"
  >
    <div>
      <b-alert show>Selecione abaixo o tipo de sinistro. </b-alert>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form
        class="mt-2"
        @submit.prevent="handleSubmit(salvarSinistro(seguro.idSeguro))"
      >
        <div class="row mb-2">
          <div class="form-group col-8">
            <label
              >Dia exato em que ocorreu o sinistro de sua bike
              <small>(obrigatório)</small></label
            >
            <ValidationProvider
              name="data"
              rules="required|data_nascimento"
              v-slot="{ errors, classes }"
            >
              <input
                type="tel"
                class="form-control"
                :class="classes"
                v-model="dataSinistro"
                v-mask="'##/##/####'"
                autocomplete="nope"
              />
              <span class="invalid-feedback">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label>Tipo de sinistro {{ tipoSinistro }}</label>
              <ValidationProvider
                name="tipo"
                rules="required"
                v-slot="{ errors, classes }"
              >
                <select
                  class="form-control"
                  v-model="tipoSinistro"
                  :class="classes"
                  data-test-id="input-estado"
                >
                  <option :value="undefined">Selecione...</option>
                  <option>Roubo</option>
                  <option>Furto qualificado</option>
                  <option>Acidente</option>
                  <option>Acidente Pessoal</option>
                  <option>Furto simples</option>
                </select>
                <span class="invalid-feedback">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
            <div v-if="tipoSinistro == 'Roubo'">
              <b-alert show
                >Considera-se “Roubo” o evento com data caracterizada, exclusivo
                e diretamente externo, súbito, involuntário e violento, que
                houve coação, ameaça ou agressão para entregar sua bike.
              </b-alert>
            </div>
            <div v-if="tipoSinistro == 'Furto qualificado'">
              <b-alert show
                >Considera-se “furto qualificado” o evento com data
                caracterizada, exclusivo e diretamente externo, súbito,
                involuntário e violento, que tenha ocorrido o rompimento de uma
                proteção para levar a sua bike, e tenha deixado vestígios do
                crime.</b-alert
              >
            </div>
            <div v-if="tipoSinistro == 'Acidente'">
              <b-alert show
                >Considera-se “acidente” o evento com data caracterizada,
                exclusivo e diretamente externo, súbito, involuntário e
                violento, que cause danos físicos a bike e que tenha ocorrido
                durante a utilização da bicicleta/bike
              </b-alert>
            </div>
            <div v-if="tipoSinistro == 'Acidente Pessoal'">
              <b-alert show
                >Considera-se “acidente pessoal” o evento com data
                caracterizada, exclusivo e diretamente externo, súbito,
                involuntário e violento, causador de lesão física que tenha
                ocorrido durante a utilização da bicicleta/bike e que tenha como
                consequência direta a Morte ou a Invalidez Permanente Total ou
                Parcial do Segurado ou torne necessário tratamento médico.
              </b-alert>
            </div>
            <div v-if="tipoSinistro == 'Furto simples'">
              <b-alert show variant="danger"
                >Sem emprego de violência e que não tenha deixado vestígio,
                furto com abuso de confiança ou mediante fraude ou destreza, com
                emprego de chave falsa.<br />
                <strong>Não há cobertura para furto simples</strong>
              </b-alert>
            </div>
          </div>
        </div>
        <div class="mb-4">
          Esse sinistro é para: <br />
          <strong> Marca</strong>: {{ seguro.marcaBike }}
          <br />
          <strong>Modelo</strong>: {{ seguro.modeloBike }}
          <br />
          <strong>Numero de série</strong>: {{ seguro.numeroSerie }}
          <br />
        </div>
        <div class="row">
          <div class="col-6">
            <b-button
              variant="danger"
              class="btn btn-block"
              @click="hideModal()"
            >
              Cancelar
            </b-button>
          </div>

          <div class="col-6">
            <b-button
              type="submit"
              class="btn btn-success btn-block"
              :disabled="invalid || loading || tipoSinistro == 'Furto simples'"
            >
              <span v-if="!loading">Abrir sinistro </span>
              <span v-else>Por favor aguarde...</span>
            </b-button>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import Swal from "sweetalert2";
import axios from "axios";
import config from "../../config";
import captureError from "../../helpers/captureError";
import moment from "moment";

export default {
  props: ["showModal", "seguro"],
  data() {
    return {
      tipoSinistro: undefined,
      loading: false,
      modificou: false,
      dataSinistro: "",
    };
  },
  methods: {
    hideModal(arg, data) {
      this.$emit("hide", data || { modificou: this.modificou });
      this.tipoSinistro = undefined;
      this.loading = false;
    },
    async salvarSinistro(id) {
      this.loading = true;
      try {
        var data = {
          id: id,
          tipoSinistro: this.tipoSinistro,
          DataSinistro: this.dataSinistro
            ? moment(this.dataSinistro, "DD/MM/YYYY").format("YYYY-MM-DD")
            : undefined,
        };

        await axios.post(`${config.API_URLV2}/seguro/abrirSinistro/`, data);
        Swal.fire({
          text:
            "Seu sinistro foi encaminhado, entraremos em contato em até 48 horas.",
          icon: "success",
        });
        this.modificou = true;

        return this.hideModal();
      } catch (e) {
        captureError(e);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text:
            "Não foi possível abrir o sinistro. Tente novamente mais tarde ou contate o suporte.",
        });

        return false;
      }
    },
  },
};
</script>
