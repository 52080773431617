var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('hr',{staticClass:"mt-1"}),(_vm.loading == false && _vm.seguros.length == 0)?_c('div',{staticClass:"row mb-3"},[_vm._m(1),_c('div',{staticClass:"text-center col-12"},[_c('b-button',{staticClass:"salvar-dados mt-3",attrs:{"variant":"success","to":"/cotacao"}},[_c('span',[_vm._v("Simular e contratar agora")])])],1)]):_vm._e(),_c('div',{staticClass:"minhas-apolices"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-6"}),(_vm.loading == false && _vm.seguros.length > 0)?_c('div',{staticClass:"col-md-6 text-md-right mb-3"},[_c('button',{directives:[{name:"b-modal",rawName:"v-b-modal.modal-alterar-cartao",modifiers:{"modal-alterar-cartao":true}}],staticClass:"btn btn-link pl-0 py-0"},[_c('i',{staticClass:"far fa-credit-card"}),_vm._v(" Alterar cartão de cobrança ")])]):_vm._e()]),(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('lottie-player',{staticClass:"mt-3",staticStyle:{"width":"72px","height":"72px","margin":"0 auto"},attrs:{"src":"/anim/mountain_bike.json","background":"transparent","speed":"1.7","autoplay":"","loop":""}}),_c('div',[_vm._v("Carregando, por favor aguarde...")])],1):_vm._e(),(_vm.loading == false && _vm.seguros.length > 0)?_c('div',_vm._l((_vm.seguros),function(item,index){return _c('div',{key:'subscription' + index,staticClass:"card mb-3",class:!item.planoOpcional &&
          item.idTipoAssinatura === _vm.config.ID_TIPO_ASSINATURA_MENSAL &&
          item.idSituacao === _vm.config.SITUACAO_APROVADA
            ? 'card-plano-pessoal'
            : ''},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('strong',[_vm._v("Seguro Bike "+_vm._s(item.plano)+" #"+_vm._s(item.codReferencia)+" ")]),(
                  item.planoOpcional &&
                    !item.ehEmAnalise &&
                    item.idSituacao === _vm.config.SITUACAO_APROVADA
                )?_c('span',{staticClass:"ml-1 badge badge-pill badge-warning"},[_vm._v("+ Acidente pessoal "+_vm._s(item.planoOpcional))]):_vm._e(),_c('div',[_c('small',[_c('span',[_vm._v("Criado em:")]),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.formatacaoData.formatarDataLocal(item.dataCriado)))])])])]),_c('div',{staticClass:"text-right pr-2 pt-2"},[_c('span',{staticClass:"h5 font-weight-normal"},[_vm._v(_vm._s(_vm.formatacaoMoeda3(item.valorPlano)))]),(
                  item.idTipoAssinatura == _vm.config.ID_TIPO_ASSINATURA_ANUAL
                )?_c('small',{staticClass:"text-muted"},[_vm._v("/ ano")]):(
                  item.idTipoAssinatura == _vm.config.ID_TIPO_ASSINATURA_SEMESTRAL
                )?_c('small',{staticClass:"text-muted"},[_vm._v("/ sem.")]):_c('small',{staticClass:"text-muted"},[_vm._v("/ mês")])])])]),_c('div',{staticClass:"card-body pb-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-lg-4 mb-3"},[_c('label',[_vm._v("Bike:")]),_c('div',[_vm._v(" "+_vm._s(item.modeloBike)+" "+_vm._s(item.marcaBike)+" "+_vm._s(item.anoBike)+" ")]),(
                  item.idTipoAssinatura ===
                    _vm.config.ID_TIPO_ASSINATURA_MENSAL &&
                    item.idSituacao === _vm.config.SITUACAO_APROVADA &&
                    (!item.temAcessorio || !item.planoOpcional)
                )?_c('b-alert',{staticClass:"mt-3 font-size-infor",attrs:{"show":"","variant":"info"}},[_c('strong',[_vm._v(" Fazer upgrade no meu seguro")]),(!item.planoOpcional)?_c('a',{staticClass:"d-block mb-1",attrs:{"href":"javascript:void(0)","target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.rota(item)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Cobertura acidente pessoal ")]):_vm._e(),(!item.temAcessorio)?_c('a',{staticClass:"d-block mb-3",attrs:{"href":"javascript:void(0)","target":"_blank"},on:{"click":function($event){$event.preventDefault();return _vm.rota2(item)}}},[_c('i',{staticClass:"fas fa-plus"}),_vm._v(" Cobertura para acessório ")]):_vm._e()]):_vm._e(),(item.temAcessorio)?_c('div',[(!item.urlNotaFiscalAcessorio)?_c('div',{staticClass:"mt-3"},[_c('a',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.exibirModalAcessorio(item.idSeguro)}}},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(" Enviar nota fiscal do acessório")])]):_c('div',{staticClass:"mt-3"},[_c('a',{attrs:{"href":item.urlNotaFiscalAcessorio,"target":"_blank"}},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(" Abrir nota fiscal do acessório")])])]):_vm._e()],1),_c('div',{staticClass:"col-sm-6 col-lg-3 mb-3"},[_c('label',[_vm._v("Situação:")]),_c('div',[(item.idSituacao == _vm.config.SITUACAO_APROVADA)?_c('span',{staticClass:"badge badge-pill badge-success"},[_vm._v("Seguro ativado")]):_vm._e(),(item.idSituacao == _vm.config.SITUACAO_ENVIADA)?_c('span',{staticClass:"badge badge-pill badge-info"},[_vm._v("Em aprovação")]):_vm._e(),(item.idSituacao == _vm.config.SITUACAO_REPROVADA)?_c('span',{staticClass:"badge badge-pill badge-danger"},[_vm._v("Seguro rejeitado")]):_vm._e(),(item.idSituacao == _vm.config.SITUACAO_CANCELADA)?_c('span',{staticClass:"badge badge-pill badge-secondary"},[_vm._v("Seguro cancelado")]):_vm._e()])]),_c('div',{staticClass:"col-sm-12 col-lg-5 mb-3"},[(
                  item.inspecStatus == _vm.config.STATUS_INSPEC_AGUARDANDO &&
                    item.idSituacao == _vm.config.SITUACAO_ENVIADA
                )?_c('div',[_c('label',[_vm._v("Vistoria:")]),_c('a',{staticClass:"d-block",attrs:{"href":item.urlInspec,"target":"_blank"}},[_c('i',{staticClass:"fas fa-external-link-square-alt"}),_vm._v(" Link de vistoria inspec ")])]):_vm._e(),(
                  item.idSituacao == _vm.config.SITUACAO_APROVADA ||
                    item.idSituacao == _vm.config.SITUACAO_CANCELADA
                )?_c('div',[_c('label',[_vm._v("Informações:")]),(item.urlApolice && item.seguroEssor)?_c('a',{staticClass:"d-block mb-3",attrs:{"href":item.urlApolice,"target":"_blank"}},[_c('i',{staticClass:"fas fa-file-alt"}),_vm._v(" Apólice do seguro ")]):_vm._e(),(item.seguroEssor)?_c('a',{staticClass:"d-block mb-3",attrs:{"href":_vm.config.URL_MANUAL_SEGURO,"target":"_blank"}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Manual do seguro ")]):_c('a',{staticClass:"d-block mb-3",attrs:{"href":item.urlManual,"target":"_blank"}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Manual do seguro ")]),(item.seguroEssor)?_c('a',{staticClass:"d-block mb-3",attrs:{"href":_vm.montarUrlCertificado(item.idSeguro),"target":"_blank"}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Certificado do seguro ")]):_c('a',{staticClass:"d-block mb-3",attrs:{"href":item.urlApolice,"target":"_blank"}},[_c('i',{staticClass:"fas fa-book"}),_vm._v(" Certificado do seguro ")]),(item.idSituacao == _vm.config.SITUACAO_APROVADA)?_c('a',{attrs:{"target":"_blank","href":("https://api.whatsapp.com/send?phone=" + (_vm.config.WHATSAPP_NUMERO) + "&text=Olá, meu nome é " + (_vm.auth.getUserName()) + ", acessei o painel do seguro e desejo comunicar um sinistro com a bike " + (item.marcaBike) + " - " + (item.modeloBike) + ".")}},[_vm._v(" Abrir sinistro ")]):_vm._e()]):_vm._e(),(item.idSituacao == _vm.config.SITUACAO_REPROVADA)?_c('div',[_c('small',[_vm._v(" O seguro da sua bike não foi aprovado. Entre em contato pelo e-mail "),_c('a',{attrs:{"href":"mailto:seguro@bikeregistrada.com.br?Subject=Gostaria de informações sobre meu seguro","target":"_blank"}},[_vm._v("seguro@bikeregistrada.com.br")]),_vm._v(" ou "),_c('a',{attrs:{"target":"_blank","href":("https://api.whatsapp.com/send?phone=" + (_vm.config.WHATSAPP_NUMERO) + "&text=Olá, meu nome é " + (_vm.auth.getUserName()) + " e preciso de atendimento.")}},[_vm._v(" Whatsapp. ")])])]):_vm._e()])]),_c('div',{staticClass:"mt-1"},[(
                item.planoOpcional &&
                  item.ehEmAnalise &&
                  item.idSituacao === _vm.config.SITUACAO_APROVADA
              )?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Parabéns! Você fez o upgrade do seu seguro e adicionou a cobertura de acidente pessoal "+_vm._s(item.planoOpcional)+". A cobertura de acidente pessoal estará em vigor na sua próxima apólice que será renovada na data "+_vm._s(_vm.formatacaoData.formatarDataLocal(item.dataRenovacao))+". ")]):_vm._e()],1),_c('div',{staticClass:"mt-1"},[(
                item.nomeAcessorio &&
                  item.ehEmAnaliseCobertura &&
                  item.idSituacao === _vm.config.SITUACAO_APROVADA
              )?_c('b-alert',{attrs:{"show":"","variant":"info"}},[_vm._v(" Parabéns! Você fez o upgrade do seu seguro e adicionou a cobertura do acessório "+_vm._s(item.nomeAcessorio)+". A cobertura para o seu acessório estará em vigor na sua próxima apólice que será renovada na data "+_vm._s(_vm.formatacaoData.formatarDataLocal(item.dataRenovacao))+". ")]):_vm._e()],1)]),_c('div',{staticClass:"card-footer p-1 text-right"},[(_vm.exibirCancelarSeguro(item))?_c('button',{staticClass:"btn btn-link btn-sm text-danger",on:{"click":function($event){return _vm.onClickCancelarSeguro(item)}}},[_vm._v(" Cancelar este seguro ")]):_vm._e()])])}),0):_vm._e()]),_c('ModalCancelarSeguroCliente',{attrs:{"seguro":_vm.seguro}}),_c('ModalAberturaSinistro',{attrs:{"showModal":_vm.showModalSinistro,"seguro":_vm.seguro},on:{"hide":_vm.hideModalModalAberturaSinistro}}),_c('ModalComprovanteAcessorio',{attrs:{"showModalAcessorio":_vm.showModalAcessorio,"idSeguro":_vm.idSeguroAcessorio},on:{"hideAcessorio":_vm.hideModalModalAcessorio}}),_c('ModalAlterarCartaoCobranca',{attrs:{"idCliente":_vm.idCliente}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pd-5"},[_c('div',{staticClass:"text-center pt-5"},[_c('i',{staticClass:"fas fa-list mr-4 icone-principal"}),_c('h3',[_vm._v("Minhas apólices")]),_c('div',{staticClass:"h-line",staticStyle:{"width":"142px"}}),_c('p',{staticClass:"mt-3 editar-conta"},[_vm._v(" Aqui você pode ver todas as suas apolices. ")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 text-center",staticStyle:{"align-self":"center"}},[_c('img',{staticClass:"rounded-circle mt-2",attrs:{"src":"/img/bot-miguel-verde.png","width":"64","alt":"Assistente virtual Miguel"}}),_c('h5',{staticClass:"font-weight-light mt-3"},[_vm._v(" Você ainda não tem nenhum seguro registrado com seu CPF. ")])])}]

export { render, staticRenderFns }